import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      staleTime: import.meta.env.PROD ? 60 * 1000 : 0,
      refetchOnWindowFocus: "always",
    },
  },
});

export function ensureLoggedIn() {
  if (!localStorage.getItem("token")) {
    window.location.href = "/login";
  }
}
